import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from '../images/sogo-logo.png';

const Header = () => {

    return (
      <div className="header">
        <div className="logo-container">
          <Link to="/" className="nav-link">
            <img src={logo} className="logo" />
          </Link>
        </div>
        <div className="links">
          <div className="nav-link-container">
            <Link to="/services" className="nav-link" data-text="Services">
              <span>Services</span>
            </Link>
          </div>
          <div className="nav-link-container">
            <Link to="/projects" className="nav-link" data-text="Projects">
              <span>Projects</span>
            </Link>
          </div>
          <div className="nav-link-container">
            <Link to="/about" className="nav-link" data-text="About">
              <span>About</span>
            </Link>
          </div>
          <div className="nav-link-container">
            <Link to="/contact" className="nav-link" data-text="Contact">
              <span>Contact</span>
            </Link>
          </div>
        </div>
      </div>
    );
};

export default Header;
