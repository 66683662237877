import React, { useEffect, useState } from "react";
import Header from "../components/header";
import logo from '../images/sogo-logo.png';

const Home = () => {

    return (
      <>
        <Header />
        <div className="home__body">
          <h1>SOGO Home Improvement LLC</h1>
          <img src={logo} className="logo" />
        </div>
      </>
    );
};

export default Home;
