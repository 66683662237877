import React, { useEffect, useState } from "react";
import Header from "../components/header";
import ginoLogan from '../images/gino-logan-2.jpg';

const Contact = () => {

    return (
      <>
        <Header />
        <div className="home__body">
          <h1>Contact</h1>
          <img src={ginoLogan} className="gino-logan" />
        </div>
      </>
    );
};

export default Contact;
